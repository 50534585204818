import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./login.css";
import axios from "axios";
import { URL } from "../AxiosUrlGlobal";
import Logo from "../../img/logo.png";
import ReactPixel from "react-facebook-pixel";

function ForGot() {
  const [forgotVerify, setForgotVerify] = useState(false);
  const [forgot, setForgot] = useState(true);
  const [user_id, setUserID] = useState();
  const [forgotNumber, setForgotNumber] = useState();
  const [VerifyCode, setVerifyCode] = useState();

  const [GetPass, setGetPass] = useState();
  const navigate = useNavigate();

  const handleForgot = async (number) => {
    if (!forgotNumber) {
      toast.error("Number required.");
      return;
    }
    setForgot(false);
    setForgotVerify(true);
    axios
      .post(`${URL}forgot/mobile`, { number })
      .then((res) => {
        if (res.data.otpStatus == true) {
          setUserID(res.data.userId);
        } else {
          console.log(res.data.message);
        }
      })
      .catch((err) => toast.error(err.response.data));
  };

  const Otp_Verify = async () => {
    if (!VerifyCode) {
      toast.error("Code required.");
      return;
    }
    axios
      .post(`${URL}otp/validate`, { userId: user_id, otp: VerifyCode })
      .then((res) => {
        console.log(res.data);

        if (res.data.message === "OTP is valid") {
          setGetPass(res.data.pass);
        }
        if (res.data === "OTP not found") {
          toast.error("OTP not found");
        }
        if (res.data === "OTP has expired") {
          toast.error("OTP has expired");
        }
        if (res.data === "OTP is invalid") {
          toast.error("OTP is invalid");
        }
      })
      .catch((err) => toast.error(err.response.data));
  };

  return (
    <div className="login-container">
      <ToastContainer />
      {/* header section start */}
      <div className="header-login">
        <div className="logo-main">
          <a href="https://ecomtechbd.com">
            <img src={Logo} width={150} />
          </a>
        </div>
        <div className="login-main">
          <ul>
            <li onClick={() => navigate("/")}>LOGIN</li>
          </ul>
        </div>
      </div>
      {/* header section end */}

      {/* login and signup section start */}
      <div className="login-signup-main">
        <div className="login-or-signup">
          <div className="login-signup-tab">
            <div
              style={{
                backgroundColor: "#207fc7",
                width: "100%",
                textAlign: "center",
                color: "#fff",
                padding: "10px",
              }}
            >
              FORGOT PASSWORD
            </div>
          </div>

          {forgot && (
            <div className="signup-inputs">
              <input
                type="number"
                placeholder="Mobile Number"
                onChange={(e) => setForgotNumber(e.target.value)}
              />
              <button
                className="login-btn"
                onClick={() => {
                  handleForgot(forgotNumber);
                }}
              >
                FORGOT PASSWORD
              </button>
            </div>
          )}

          {forgotVerify && (
            <div className="signup-inputs">
              {GetPass && (
                <>
                  <p
                    style={{
                      textAlign: "center",
                      border: "1px solid #ccc",
                      padding: "7px",
                    }}
                  >
                    আপনার নতুন পাসওয়ার্ড {GetPass}
                  </p>
                  <p
                    style={{
                      textAlign: "center",
                      border: "1px solid #ccc",
                      padding: "7px",
                      cursor: "pointer",
                      backgroundColor: "#225cb7",
                      color: "#fff",
                    }}
                    onClick={() => navigate("/")}
                  >
                    LOGIN
                  </p>
                </>
              )}
              {!GetPass && (
                <p
                  style={{
                    textAlign: "center",
                    border: "1px solid #ccc",
                    padding: "7px",
                  }}
                >
                  আপনার মোবাইলে একটি ভেরিফিকেসন কোড পাঠানো হয়েছে কোডটি নিচে
                  লিখুন{" "}
                </p>
              )}
              {!GetPass && (
                <input
                  type="number"
                  placeholder="Verification Code"
                  onChange={(e) => {
                    setVerifyCode(e.target.value);
                  }}
                />
              )}
              {!GetPass && (
                <button className="login-btn" onClick={Otp_Verify}>
                  Verify-Code
                </button>
              )}
              {!GetPass && (
                <p
                  style={{ textAlign: "center", cursor: "pointer" }}
                  onClick={() => {
                    setForgot(true);
                    setForgotVerify(false);
                  }}
                >
                  Code-Not-Receive? Resend
                </p>
              )}
            </div>
          )}
        </div>
      </div>
      {/* login and signup section end */}
    </div>
  );
}

export default ForGot;
