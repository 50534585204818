import React, { useState, useRef, useEffect } from "react";
import "./PrintModal.css";
import myAxios from "../AxiosUrl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import { useReactToPrint } from "react-to-print";
import Moment from "moment";

function PrintModal({ setOpenPrint, printModalData, setRefresh }) {
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [store, setStore] = useState([]);
  const [tp, setTp] = useState([]);
  const [Dhaka, setDhaka] = useState();
  const [Outside, setOutside] = useState();
  const data = printModalData;
  const uid = localStorage.getItem("id");
  const DiscountLoop = data.discount;

  let PriceAfterAdvance;
  if (data.advance && data.discount) {
    let ad = parseInt(data.advance) + parseInt(data.discount);
    PriceAfterAdvance = data.totalPrice - ad;
  } else if (data.advance) {
    PriceAfterAdvance = data.totalPrice - parseInt(data.advance);
  } else if (data.discount) {
    PriceAfterAdvance = data.totalPrice - parseInt(data.discount);
  } else {
    PriceAfterAdvance = data.totalPrice;
  }

  const componentRef = useRef();
  const PrintInvoiceRef = useRef();
  const handleprint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "emp-data",
    onAfterPrint: () => {
      // myAxios
      //   .post(`edit-order-status/${data._id}`, { status: 4 })
      //   .then((res) => {
      //     toast.success("Print completed");
      //     setOpenPrint(false);
      //     setRefresh(true);
      //     setLoading(true);
      //   })
      //   .catch((err) => console.log(err.response.data));
      toast.success("Print completed");
      setOpenPrint(false);
      setRefresh(true);
      setLoading(true);
    },
  });
  const PrintInvoice = useReactToPrint({
    content: () => PrintInvoiceRef.current,
    documentTitle: "emp-data",
    onAfterPrint: () => {
      // myAxios
      //   .post(`edit-order-status/${data._id}`, { status: 4 })
      //   .then((res) => {
      //     toast.success("Print completed");
      //     setOpenPrint(false);
      //     setRefresh(true);
      //     setLoading(true);
      //   })
      //   .catch((err) => console.log(err.response.data));
      toast.success("Print completed");
      setOpenPrint(false);
      setRefresh(true);
      setLoading1(true);
    },
  });
  useEffect(() => {
    myAxios
      .get(`get-domain/${uid}`)
      .then((res) => {
        setStore(res.data);
        setDhaka(res.data[0].Dhaka);
        setOutside(res.data[0].Outside);
        if (data.deliveryCharge === "dhaka") {
          setTp(parseInt(res.data[0].Dhaka) + parseInt(PriceAfterAdvance));
        } else {
          setTp(parseInt(res.data[0].Outside) + parseInt(PriceAfterAdvance));
        }
      })
      .catch((err) => console.log(err.response.data));
  }, []);
  return (
    <div className="modalBackground">
      <ToastContainer />
      <div className="modalContainer">
        <div className="footer">
          <button
            onClick={() => {
              handleprint();
              setLoading(true);
            }}
          >
            {loading ? "Printing..." : "Print Sticker"}
          </button>
          <button
            onClick={() => {
              PrintInvoice();
              setLoading1(true);
            }}
          >
            {loading1 ? "Printing..." : "Print Invoice"}
          </button>
          <button
            onClick={() => {
              setOpenPrint(false);
            }}
            id="cancelBtn"
          >
            Cancel
          </button>
        </div>
        <div ref={componentRef} className="print-main">
          <div className="print-inside">
            <h2>{store.length > 0 && store[0].store}</h2>
            <p>{`Name: ${data.cName}`}</p>
            <p>{`Mobile: ${data.cMobile}`}</p>
            <p>{`Address: ${data.address.substring(0, 80)}`}</p>
            <p>{`Total Due: ${tp}`}</p>
            {data.product.map((data) => {
              return (
                <p>
                  Product
                  {`: ( ${data.Quality} পিস ${data.pCode} কালার ${data.color} ${
                    data.Size ? `সাইজ ${data.Size}` : ""
                  } )`}
                </p>
              );
            })}
            <p
              style={{
                fontSize: "13px",
                lineHeight: "15px",
                marginTop: "5px",
              }}
            >{`পণ্য খুলে দেখতে চাইলে ডেলিভারি চার্জ ঢাকা ${Dhaka} ঢাকার বাহিরে ${Outside} টাকা নিয়ে তারপর দেখান`}</p>
          </div>
        </div>
        <div ref={PrintInvoiceRef} className="PrintInvoice">
          <div className="InvoiceTitle">Invoice</div>
          <div className="LogoDate">
            <div className="InvoiceLogo">
              <img src={store.length > 0 && store[0].logo} height={60} />
            </div>
            <div>
              <div>Date:{Moment(data.updateDate).format("DD-MM-YYYY")}</div>
              <div>Invoice: {data.InvoiceId}</div>
            </div>
          </div>
          <div className="FromTo">
            <div style={{ width: "50%" }}>
              <div>From</div>
              <div>Store: {store.length > 0 && store[0].store}</div>
              <div>Domain: {store.length > 0 && store[0].domain}</div>
              <div>Mobile: {store.length > 0 && store[0].number}</div>
            </div>
            <div style={{ width: "50%" }}>
              <div>
                <div>To</div>
                <div>{data.cName}</div>
                <div>{data.cMobile}</div>
                <div>{data.address}</div>
              </div>
            </div>
          </div>
          <div className="productList">
            <table>
              <tr>
                <th>Product Title</th>
                <th>Image</th>
                <th>Sku</th>
                <th>Color</th>
                <th>Size</th>
                <th>Price</th>
                <th>Discount</th>
                <th>Quantity</th>
                <th>Amount</th>
              </tr>
              {data.orderData &&
                data.orderData.map((data) => {
                  return (
                    <>
                      <tr>
                        <td>{data.title}</td>
                        <td>
                          <img src={data.image} height={40} width={40} />
                        </td>
                        <td>{data.pCode}</td>
                        <td>{data.colorName}</td>
                        <td>{data.Size ? data.Size : "No"}</td>
                        <td>{data.price}</td>
                        <td>{DiscountLoop ? DiscountLoop : 0}</td>
                        <td>{data.quantity}</td>
                        <td>{data.quantity * data.price}</td>
                      </tr>
                    </>
                  );
                })}
            </table>
          </div>
          <div className="InvoiceCal">
            <div className="InvoiceCalItem">
              <div>Note:</div>
              <div>{data.OrderNote}</div>
            </div>
            <div className="InvoiceCalItem">
              <div>Subtotal:</div>
              <div>{data.totalPrice}</div>
            </div>
            <div className="InvoiceCalItem">
              <div>Discount:</div>
              <div>{data.discount ? data.discount : "0"}</div>
            </div>
            <div className="InvoiceCalItem">
              <div>Advance:</div>
              <div>{data.advance ? data.advance : "0"}</div>
            </div>
            <div className="InvoiceCalItem">
              <div>Delivery Charge:</div>
              <div>{data.deliveryCharge === "outside" ? Outside : Dhaka}</div>
            </div>
            <div className="InvoiceCalItem">
              <div>Total Payable:</div>
              <div>{tp}</div>
            </div>
            <div className="InvoiceCalItem removeBorder">
              <div>Payment Type:</div>
              <div>CASH_ON_DELIVERY</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrintModal;
