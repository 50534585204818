import React, { useState, useEffect, useRef } from "react";
import "./Category.css";
import Button from "@mui/material/Button";
import Slider from "@mui/material/Slider"; // Import Slider from Material UI
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import EditCategoryModal from "../EditCategoryModal/EditCategoryModal";
import CategoryModal from "../CategoryModal/CategoryModal";
import AxiosUrl from "../AxiosUrl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactLoading from "react-loading";
import { useNavigate } from "react-router-dom"; // Import useNavigate

function Category() {
  const uid = localStorage.getItem("id");
  const [category, setCategory] = useState([]);
  const [open, setOpenModal] = useState(false);
  const [openCategory, setOpenCategoryModal] = useState(false);
  const [edit, setEdit] = useState("");
  const [refresh, setRefresh] = useState(0); // Refresh as a number
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate(); // Initialize useNavigate

  const tableContainerRef = useRef(null); // Reference for the table container
  const [scrollValue, setScrollValue] = useState(0); // State to track the scroll value

  // Auto-scroll effect after data is fetched
  useEffect(() => {
    if (category.length > 0) {
      let direction = 1; // 1 for right, -1 for left
      const scrollInterval = setInterval(() => {
        setScrollValue((prev) => {
          let newValue = prev + direction * 1; // Adjust speed by changing the multiplier (1)
          if (newValue >= 100) {
            direction = -1; // Change direction to left
          } else if (newValue <= 0) {
            clearInterval(scrollInterval); // Clear interval after the full cycle (left to right and back to left)
          }
          return newValue;
        });
      }, 20); // Adjust timing for smoother animation

      return () => clearInterval(scrollInterval); // Clear the interval on cleanup
    }
  }, [category]);

  useEffect(() => {
    if (category.length > 0 && tableContainerRef.current) {
      tableContainerRef.current.scrollTo({
        left: tableContainerRef.current.scrollWidth * (scrollValue / 100),
        behavior: "smooth", // Smooth scrolling
      });
    }
  }, [scrollValue]);

  useEffect(() => {
    if (!open && !openCategory) {
      getCategory();
    }
  }, [refresh, open, openCategory]);

  const getCategory = () => {
    setLoading(true);
    AxiosUrl.get(`/category/${uid}`)
      .then((res) => {
        setCategory(res.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const deleteCategory = (cid) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this category?"
    );
    if (confirmed) {
      AxiosUrl.post(`/delete-category/${uid}`, { cid })
        .then((res) => {
          if (res.data.acknowledged) {
            toast.success("Deleted successfully.");
            setRefresh((prev) => prev + 1); // Increment refresh count
          }
        })
        .catch(() => {
          toast.error("Failed to delete the category.");
        });
    }
  };

  const handleSubCategoryClick = (categoryId) => {
    navigate(`/subcategories/${categoryId}`); // Navigate to SubCategoryList with the categoryId
  };

  const handleSliderChange = (event, newValue) => {
    setScrollValue(newValue); // Update the scroll value state
    if (tableContainerRef.current) {
      tableContainerRef.current.scrollTo({
        left: tableContainerRef.current.scrollWidth * (newValue / 100),
        behavior: "smooth",
      });
    }
  };

  return (
    <div style={{ minHeight: "90vh" }}>
      <ToastContainer />
      {open && (
        <EditCategoryModal
          setOpenModal={setOpenModal}
          data={edit}
          setRefresh={() => setRefresh((prev) => prev + 1)} // Increment refresh count
        />
      )}
      {openCategory && (
        <CategoryModal
          setOpenCategoryModal={setOpenCategoryModal}
          open={openCategory}
          setRefresh={() => setRefresh((prev) => prev + 1)} // Increment refresh count
        />
      )}
      <div className="category-main-1234">
        <div className="category-video-1234">
          <div>
            ক্যাটাগরি কিভাবে কাজ করে দেখুন ➡️{" "}
            <a href="#" target="_blank">
              VIDEO LINK
            </a>{" "}
          </div>
        </div>
        <div
          style={{
            boxShadow: "rgba(0, 0, 0, 0.36) 0px 0px 4px 0px",
            borderRadius: 10,
            overflow: "hidden",
            marginBottom: 10,
          }}
        >
          <div className="create-category-1234">
            <Button
              variant="contained"
              onClick={() => setOpenCategoryModal(true)}
              style={{ fontSize: 13, fontWeight: "bold" }}
            >
              Add Category
            </Button>
          </div>

          <div className="slider-container-1234">
            <p>
              টেবিল টি ডান দিকে বা বাম দিকে দেখতে নিচের স্লাইড টি ডানে বা বামে
              টান দিন{" "}
            </p>
            <Slider
              value={scrollValue}
              aria-label="Scroll"
              valueLabelDisplay="auto"
              onChange={handleSliderChange}
              step={1} // Ensure small steps for smoothness
              min={0}
              max={100}
            />
          </div>

          <TableContainer
            component={Paper}
            style={{
              borderRadius: 0,
              overflowX: "auto",
            }} // Enable horizontal scrolling
            ref={tableContainerRef} // Attach the ref
          >
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left" width={70}>
                    Image
                  </TableCell>
                  <TableCell align="center" width={150}>
                    Category Name
                  </TableCell>
                  <TableCell align="center" width={70}>
                    Status
                  </TableCell>
                  <TableCell align="center" width={150}>
                    Add Subcategory
                  </TableCell>
                  <TableCell align="center" width={70}>
                    Edit
                  </TableCell>
                  <TableCell align="right" width={80}>
                    Delete
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading && (
                  <TableRow>
                    <TableCell colSpan={6} align="center">
                      <ReactLoading
                        type="cylon"
                        color="rgb(226,115,29)"
                        height={60}
                        width={60}
                      />
                    </TableCell>
                  </TableRow>
                )}
                {!loading && category.length <= 0 ? (
                  <TableRow>
                    <TableCell colSpan={6} align="center">
                      <div className="no-data-available-1234">
                        No data available
                      </div>
                    </TableCell>
                  </TableRow>
                ) : (
                  category.map((data) => (
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": {
                          borderBottom: "1px solid rgba(224, 224, 224, 1)", // Ensures last row has a border
                        },
                        borderBottom: "1px solid rgba(224, 224, 224, 1)", // Add border to all rows
                      }}
                      key={data._id}
                    >
                      <TableCell align="left" component="th" scope="row">
                        <img
                          src={data.imgUrl}
                          height={60}
                          width={60}
                          alt={data.name}
                        />
                      </TableCell>
                      <TableCell
                        align="center"
                        component="th"
                        scope="row"
                        style={{ fontSize: 13, fontWeight: "bold" }}
                      >
                        {data.name.length > 14
                          ? `${data.name.slice(0, 14)}...`
                          : data.name}
                      </TableCell>
                      <TableCell align="center" component="th" scope="row">
                        <Button
                          style={{
                            backgroundColor:
                              data.status === 1
                                ? "rgb(147, 46, 93)"
                                : "#275727",
                            color: "#fff",
                            padding: "3px",
                            fontSize: "11px",
                            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                          }}
                        >
                          {data.status === 1 ? "Deactive" : "Active"}
                        </Button>
                      </TableCell>
                      <TableCell
                        align="center"
                        component="th"
                        scope="row"
                        style={{ position: "relative" }}
                      >
                        <Button
                          style={{
                            backgroundColor: "rgb(56, 88, 121)",
                            color: "#fff",
                            padding: "4px",
                            fontSize: "11px",
                            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                            position: "relative",
                          }}
                          onClick={() => handleSubCategoryClick(data._id)}
                        >
                          Sub-Category
                          {data.subcategories.length > 0 ? (
                            <span className="subcategory-badge">
                              {data.subcategories.length > 9
                                ? "9+"
                                : data.subcategories.length}
                            </span>
                          ) : (
                            <span className="subcategory-badge">0</span>
                          )}
                        </Button>
                      </TableCell>
                      <TableCell align="center" component="th" scope="row">
                        <Button
                          style={{
                            backgroundColor: "#275727",
                            color: "#fff",
                            padding: "3px",
                            fontSize: "11px",
                            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                          }}
                          onClick={() => {
                            setEdit(data);
                            setOpenModal(true);
                          }}
                        >
                          Edit
                        </Button>
                      </TableCell>

                      <TableCell align="right">
                        <Button
                          style={{
                            backgroundColor: "#9b2a2a",
                            color: "#fff",
                            padding: "3px",
                            fontSize: "11px",
                            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                          }}
                          onClick={() => deleteCategory(data._id)}
                        >
                          Delete
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <div className="slider-container-1234">
            <Slider
              value={scrollValue}
              aria-label="Scroll"
              valueLabelDisplay="auto"
              onChange={handleSliderChange}
              step={1} // Ensure small steps for smoothness
              min={0}
              max={100}
            />
            <p>
              টেবিল টি ডান দিকে বা বাম দিকে দেখতে উপরের স্লাইড টি ডানে বা বামে
              টান দিন{" "}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Category;
