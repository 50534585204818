import React, { useState, useEffect } from "react";
import "./TeamModal.css";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import AxiosUrl from "../AxiosUrl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

function Modal({ setOpenCategoryModal }) {
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setloading] = useState(false);
  const uid = localStorage.getItem("id");
  const fromData = {
    uid,
    name,
    mobile,
    password
  };
  const submitTeam = () => {
    setloading(true);
    if (!name || !mobile || !password) {
      return setloading(false);
    }

    AxiosUrl.post(`/add-team/${uid}`, fromData)
      .then((data) => {
        if (data.data === "Create_team success..") {
          toast.success(data.data);
          setOpenCategoryModal(false);
        }
        if (data.data === "The member already exist") {
          toast.error(data.data);
        }
        if(data.data === "Something wrong on server"){
          toast.error(data.data);
        }
        setloading(false);
        setName("");
        setMobile("")
        setPassword("")
      })
      .catch((error) => {});
  };

  return (
    <div className="modalBackground">
      <ToastContainer />
      <div className="modalContainer">
        <div className="titleCloseBtn">
          <button onClick={() => setOpenCategoryModal(false)}>X</button>
        </div>
        <div className="title">
          <Box sx={{ width: 500, maxWidth: "100%", marginBottom:"10px" }}>
            <TextField
              fullWidth
              label="Name"
              id="fullWidth"
              onChange={(e) => setName(e.target.value)}
              value={name}
            />
          </Box>
          <Box sx={{ width: 500, maxWidth: "100%", marginBottom:"10px" }}>
            <TextField
              fullWidth
              label="Mobile"
              type="number"
              id="fullWidth"
              onChange={(e) => setMobile(e.target.value)}
              value={mobile}
            />
          </Box>
          <Box sx={{ width: 500, maxWidth: "100%" }}>
            <TextField
              fullWidth
              label="Password"
              type="password"
              id="fullWidth"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
            />
          </Box>
        </div>

        <div className="footer">
          <button
            onClick={() => {
              setOpenCategoryModal(false);
            }}
            id="cancelBtn"
          >
            Cancel
          </button>
          <button
            onClick={() => {
              submitTeam();
            }}
          >
            {loading ? "Loading.." : "Continue"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default Modal;
