import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./OrderList.css";
import myAxios from "../AxiosUrl";
import OrderViewModal from "../OrderViewModal/OrderViewModal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import PrintModal from "../PrintModal/PrintModal";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import { HiSearchCircle } from "react-icons/hi";
import LinearProgress from "@mui/material/LinearProgress";
import AssignModal from "../AssignModal/AssignModal";
import ChangeStatusModal from "../ChangeStatusModal/ChangeStatusModal";
import Papa from "papaparse";

function Order() {
  const [DateFrom, setDateFrom] = useState(new Date());
  DateFrom.setHours(0);
  DateFrom.setMinutes(0);
  DateFrom.setSeconds(0);
  const [DateTo, setDateTo] = useState(new Date());
  DateTo.setHours(0);
  DateTo.setMinutes(0);
  DateTo.setSeconds(0);
  const [DateSearch, setDateSearch] = useState(false);
  const [RefreshSe, setRefreshSe] = useState(1);
  const uid = localStorage.getItem("id");
  const navigate = useNavigate();
  const [Totalpage, setTotalPage] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setPageLimit] = useState("all");
  const [OrderCal, setOrderCal] = useState([]);
  const [pData, setPdata] = useState([]);
  const [modalData, setmodalData] = useState([]);
  const [open, setOpenModal] = useState(false);
  const [searchN, setSearchNum] = useState("");
  const [orderStatus, setOrderStatus] = useState();
  const [refresh, setRefresh] = useState(false);
  const [openPrint, setOpenPrint] = useState(false);
  const [printModalData, setPrintModalData] = useState();
  const [loading, setLoading] = useState(false);
  const [FilterDate, setFilterDate] = useState("Today");
  const [ReceiveReturn, setReceiveReturn] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [SteadFastLoading, setSteadFastLoading] = useState(false);
  const [AssignOpen, setAssignOpen] = useState(false);
  const [ChangeStatusOpen, setChangeStatusOpen] = useState(false);
  const [SelecetUser, setSelecetUser] = useState([]);
  const [team, setTeam] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [Dhaka, setDhaka] = useState();
  const [Outside, setOutside] = useState();

  const PageNumber = (e, p) => {
    setLoading(true);
    setPage(p);
  };

  const handleToggleItem = (itemId) => {
    setSelectedItems((prevSelectedItems) =>
      prevSelectedItems.includes(itemId)
        ? prevSelectedItems.filter((item) => item !== itemId)
        : [...prevSelectedItems, itemId]
    );
  };

  const handleToggleAll = () => {
    if (selectAll) {
      setSelectedItems([]);
    } else {
      setSelectedItems(OrderD);
    }
    setSelectAll(!selectAll);
  };

  const DownloadCSV = async () => {
    const Data = [];
    selectedItems.map((order) => {
      let PriceAfterAdvance;
      if (order.advance && order.discount) {
        let ad = parseInt(order.advance) + parseInt(order.discount);
        PriceAfterAdvance = order.totalPrice - ad;
      } else if (order.advance) {
        PriceAfterAdvance = order.totalPrice - parseInt(order.advance);
      } else if (order.discount) {
        PriceAfterAdvance = order.totalPrice - parseInt(order.discount);
      } else {
        PriceAfterAdvance = order.totalPrice;
      }

      const WithDeliveryCharge =
        order.deliveryCharge === "dhaka" ? Dhaka : Outside;
      const cod = WithDeliveryCharge + parseInt(PriceAfterAdvance);

      let pushData = {
        Name: order.cName,
        Mobile: order.cMobile,
        Addres: order.address,
        Amount: cod,
      };
      Data.push(pushData);
    });
    const csv = Papa.unparse(Data, {
      header: true,
    });

    const blob = new Blob([csv], { type: "text/csv" });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "data.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setSelectedItems([]);
  };

  const OrderD = pData.filter((fdata) => {
    if (!orderStatus || orderStatus === "undefined") {
      return fdata;
    } else if (ReceiveReturn) {
      return fdata.CourierReturn === "no" && fdata.orderStatus === 6;
    } else {
      return fdata.orderStatus === orderStatus;
    }
  });

  const getData = () => {
    setLoading(true);
    const Nurl = `get-order?searchN=${searchN}&orderStatus=${orderStatus}&uid=${uid}&page=${page}&limit=${limit}&FilterDate=${FilterDate}&DateFrom=${DateFrom}&DateTo=${DateTo}&DateSearch=${DateSearch}`;
    myAxios
      .get(Nurl)
      .then((res) => {
        setPdata(res.data.data);
        setTotalPage(res.data.total);
        setLoading(false);
        setDateSearch(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    let Today_Adv = 0;
    OrderD.map((data) => {
      if (data.advance) {
        Today_Adv += data.advance * 1;
      }
    });

    const today = pData.filter((order) => [5].includes(order.orderStatus));

    let TakaTotalDay = 0;
    let TodaySale = 0;
    today.map((data) => {
      TakaTotalDay += data.totalPrice * 1;
      data.orderData.map(async (totalquantity) => {
        TodaySale += totalquantity.quantity * 1;
      });
    });

    const todayProfit = pData.filter((order) =>
      [2, 3, 4, 11].includes(order.orderStatus)
    );

    let ProfitToday = 0;
    let DiscountToday = 0;

    todayProfit.map((data) => {
      if (data.discount) {
        DiscountToday += data.discount * 1;
      }
      if (data.profit) {
        ProfitToday += data.profit * 1;
      }
    });

    const todayReturn = pData.filter((order) =>
      [6].includes(order.orderStatus)
    );

    let returnAmunt = 0;
    let returnProfit = 0;
    todayReturn.map((data) => {
      if (data.deliveryCharge === "dhaka") {
        const amount = 55;
        if (data.ReturnAmount) {
          if (parseInt(data.ReturnAmount) > amount) {
            returnProfit = data.ReturnAmount - amount;
          } else {
            returnAmunt += amount - data.ReturnAmount * 1;
          }
        } else {
          returnAmunt += amount;
        }
      }
      if (data.deliveryCharge === "outside") {
        const amount = 95;
        if (data.ReturnAmount) {
          if (parseInt(data.ReturnAmount) > amount) {
            returnProfit = data.ReturnAmount - amount;
          } else {
            returnAmunt += amount - data.ReturnAmount * 1;
          }
        } else {
          returnAmunt += amount;
        }
      }
    });

    const OrderDataCal = {
      advance: Today_Adv,
      discount: DiscountToday,
      sale: TodaySale,
      taka: TakaTotalDay,
      Profit: ProfitToday - returnAmunt + returnProfit,
      Return: todayReturn.length,
    };

    setOrderCal(OrderDataCal);
  }, [RefreshSe, refresh, pData]);

  const AlartSubmit = (id) => {
    setRefresh(false);
    confirmAlert({
      title: "DELETE CONFIRMATION",
      message: "Are you sure to delete the product.",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteOrder(id),
        },
        {
          label: "No",
          onClick: () => null,
        },
      ],
    });
  };

  const deleteOrder = (id) => {
    const orderDelete = `delete-order/${id}`;
    myAxios
      .delete(orderDelete)
      .then((res) => {
        setRefresh(true);
        toast.success("Deleted..");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const SteadFastEntry = () => {
    setSteadFastLoading(true);
    myAxios
      .post(`steadfast/${uid}`, selectedItems)
      .then((res) => {
        setSteadFastLoading(false);
        setSelectedItems([]);
        toast.success(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getData();
  }, [refresh, searchN, page, limit, FilterDate, RefreshSe]);

  useEffect(() => {
    const url = `get-team/${uid}`;
    myAxios
      .get(url)
      .then((res) => {
        setSelecetUser(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    myAxios
      .get(`get-domain/${uid}`)
      .then((res) => {
        setDhaka(parseInt(res.data[0].Dhaka));
        setOutside(parseInt(res.data[0].Outside));
      })
      .catch((err) => console.log(err.response.data));
  }, []);

  return (
    <div
      style={{
        maxWidth: "98%",
        minHeight: "90vh",
        margin: "0 auto",
      }}
    >
      <div className="order-Video">
        <div>
          অর্ডার পেইজ কিভাবে কাজ করে দেখুন ➡️{" "}
          <a href="#" target="_blank">
            VIDEO LINK
          </a>{" "}
        </div>
      </div>
      <ToastContainer />
      {AssignOpen && (
        <AssignModal
          setAssignOpen={setAssignOpen}
          data={selectedItems}
          setRefresh={setRefresh}
          setSelectedItems={setSelectedItems}
        />
      )}
      {ChangeStatusOpen && (
        <ChangeStatusModal
          setChangeStatusOpen={setChangeStatusOpen}
          data={selectedItems}
          setRefresh={setRefresh}
          setSelectedItems={setSelectedItems}
        />
      )}
      {open && (
        <OrderViewModal
          modalData={modalData}
          setOpenModal={setOpenModal}
          setRefresh={setRefresh}
        />
      )}
      {openPrint && (
        <PrintModal
          printModalData={printModalData}
          setOpenPrint={setOpenPrint}
          setRefresh={setRefresh}
        />
      )}
      <section
        style={{
          border: "2px solid #ccc",
          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
        }}
      >
        {loading && (
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        )}

        <div className="filter-2nd">
          <div className="sort-day">
            <select
              name="filterData"
              className="date-filter"
              onChange={(e) => setFilterDate(e.target.value)}
            >
              <option value="Today">Today</option>
              <option value="Yesterday">Yesterday</option>
              <option value="7 Days">Week</option>
              <option value="Month">Month</option>
              <option value="Last Month">Last Month</option>
              <option value="all">All Orders</option>
            </select>
          </div>

          <div className="date-date-Picker">
            <DatePicker
              selected={DateFrom}
              onChange={(date) => setDateFrom(date)}
            />
            <p style={{ paddingRight: "10px" }}>To</p>
            <DatePicker
              selected={DateTo}
              onChange={(date) => setDateTo(date)}
            />
            <HiSearchCircle
              className="search-date"
              onClick={() => {
                setDateSearch(true);
                setRefreshSe(RefreshSe + 1);
              }}
            />
          </div>

          <h3
            style={{
              color: "rgb(88, 82, 82)",
              margin: "0px",
              padding: "0px",
              fontSize: "15px",
            }}
          >
            Order: {OrderD.length}
          </h3>
          <h3
            style={{
              color: "rgb(88, 82, 82)",
              margin: "0px",
              padding: "0px",
              fontSize: "15px",
            }}
          >
            Taka: {OrderCal.taka}
          </h3>
          <h3
            style={{
              color: "rgb(88, 82, 82)",
              margin: "0px",
              padding: "0px",
              fontSize: "15px",
            }}
          >
            Profit: {OrderCal.Profit}
          </h3>
          {selectedItems.length > 0 && (
            <h3
              style={{
                color: "rgb(88, 82, 82)",
                margin: "0px",
                padding: "0px",
              }}
            >
              {selectedItems.length}
            </h3>
          )}

          {/* <div>
            <label for="ShowOrders">Team: </label>
            <select name="Team" className="ShowOrder">
              <option value="">Select Member</option>
              {SelecetUser &&
                SelecetUser.map((data, index) => {
                  return (
                    <>
                      <option value={data._id} key={index}>
                        {data.name}
                      </option>
                    </>
                  );
                })}
            </select>
          </div> */}
          <div>
            <label for="ShowOrders">Show Order: </label>
            <select
              name="cars"
              className="ShowOrder"
              onChange={(e) => setPageLimit(e.target.value)}
            >
              <option value="all">All</option>
              <option value="100">100</option>
              <option value="250">250</option>
              <option value="500">500</option>
            </select>
          </div>
        </div>
        <div
          onClick={() => navigate("/advance-report")}
          className="advance-report"
        >
          Advance Order Report
        </div>
        <div className="fillter-main">
          <div className="filter-status">
            <ul>
              <li
                className={!orderStatus ? "active" : ""}
                onClick={() => {
                  setOrderStatus();
                  setReceiveReturn(false);
                }}
              >
                All-Order
              </li>
              <li
                className={orderStatus === 1 ? "active" : ""}
                onClick={() => {
                  setOrderStatus(1);
                  setReceiveReturn(false);
                }}
              >
                New
              </li>
              <li
                className={orderStatus === 2 ? "active" : ""}
                onClick={() => {
                  setOrderStatus(2);
                  setReceiveReturn(false);
                }}
              >
                Call-Done
              </li>
              <li
                className={orderStatus === 7 ? "active" : ""}
                onClick={() => {
                  setOrderStatus(7);
                  setReceiveReturn(false);
                }}
              >
                Call Not-Receiced
              </li>
              <li
                className={orderStatus === 8 ? "active" : ""}
                onClick={() => {
                  setOrderStatus(8);
                  setReceiveReturn(false);
                }}
              >
                Ask-Advance
              </li>

              <li
                className={orderStatus === 9 ? "active" : ""}
                onClick={() => {
                  setOrderStatus(9);
                  setReceiveReturn(false);
                }}
              >
                Ship-Later
              </li>
              <li
                className={orderStatus === 3 ? "active" : ""}
                onClick={() => {
                  setOrderStatus(3);
                  setReceiveReturn(false);
                }}
              >
                Courier-Done
              </li>
              <li
                className={orderStatus === 10 ? "active" : ""}
                onClick={() => {
                  setOrderStatus(10);
                  setReceiveReturn(false);
                }}
              >
                Waiting
              </li>

              <li
                className={orderStatus === 4 ? "active" : ""}
                onClick={() => {
                  setOrderStatus(4);
                  setReceiveReturn(false);
                }}
              >
                Print
              </li>
              <li
                className={orderStatus === 5 ? "active" : ""}
                onClick={() => {
                  setOrderStatus(5);
                  setReceiveReturn(false);
                }}
              >
                Cancel
              </li>
              <li
                className={orderStatus === 6 ? "active" : ""}
                onClick={() => {
                  setOrderStatus(6);
                  setReceiveReturn(false);
                }}
              >
                Return
              </li>
              <li
                className={orderStatus === 11 ? "active" : ""}
                onClick={() => {
                  setOrderStatus(11);
                  setReceiveReturn(false);
                }}
              >
                Overseas
              </li>

              <li
                className={ReceiveReturn === true ? "active" : ""}
                onClick={() => {
                  setReceiveReturn(true);
                  setOrderStatus(100);
                }}
              >
                Pending-Return
              </li>
              <li
                className={orderStatus === 12 ? "active" : ""}
                onClick={() => {
                  setOrderStatus(12);
                  setReceiveReturn(false);
                }}
              >
                Delivery-Issue
              </li>
              <li
                className={orderStatus === 13 ? "active" : ""}
                onClick={() => {
                  setOrderStatus(13);
                  setReceiveReturn(false);
                }}
              >
                Payment-Received
              </li>
            </ul>
          </div>

          <div className="search">
            <form role="search">
              <input
                onChange={(e) => {
                  setSearchNum(e.target.value);
                  setLoading(true);
                }}
                className="searchin"
                type="search"
                placeholder="Search Number.."
                autoFocus
                required
                value={searchN}
              />
            </form>
            {/* <form>
              <label for="gsearch">Search Number: </label>
              <input type="search" id="gsearch" name="gsearch" />
              <input type="submit" />
            </form> */}
          </div>
        </div>
        {selectedItems.length > 0 && (
          <div className="when-select">
            <div
              className="when-select-state"
              onClick={() => setChangeStatusOpen(true)}
            >
              Change Status
            </div>
            <div className="when-select-state">Print Invoice</div>
            <div className="when-select-state">Print Sticker</div>
            <div className="when-select-state" onClick={SteadFastEntry}>
              {SteadFastLoading ? "Loading.." : "SteadFast Entry"}
            </div>
            <div
              className="when-select-state"
              onClick={() => setAssignOpen(true)}
            >
              Assign Order
            </div>
            <div className="when-select-state" onClick={() => DownloadCSV()}>
              Download CSV
            </div>
            <div
              style={{ backgroundColor: "rgb(203 31 31)" }}
              className="when-select-state"
            >
              Delete Order
            </div>
          </div>
        )}
        <div className="tbl-header">
          {/* <table cellPadding="0" cellSpacing="0" border="0">
            <thead>
              <tr>
                <th style={{ textAlign: "left" }}>
                  <button
                    style={{
                      marginRight: "20px",
                      backgroundColor: "rgb(23 57 57 / 58%)",
                      padding: "2px",
                      border: "none",
                      borderRadius: "5px",
                    }}
                  >
                    <input
                      type="checkbox"
                      checked={selectAll}
                      onChange={handleToggleAll}
                    />
                  </button>
                  Name
                </th>
                <th>Mobile</th>
                <th>Amount</th>
                <th>Item</th>
                <th>Assigned_To</th>
                <th>Status</th>
                <th>Edit / Delete / Print</th>
              </tr>
            </thead>
          </table> */}
        </div>
        <div className="tbl-content">
          <table cellPadding="0" cellSpacing="0" border="0">
            <tbody>
              {OrderD.length === 0 ? (
                <div
                  style={{
                    padding: "20px",
                    textAlign: "center",
                    color: "#888",
                  }}
                >
                  No Orders Found
                </div>
              ) : (
                <>
                  <div className="tbl-header">
                    <table cellPadding="0" cellSpacing="0" border="0">
                      <thead>
                        <tr>
                          <th style={{ textAlign: "left" }}>
                            <button
                              style={{
                                marginRight: "20px",
                                backgroundColor: "rgb(23 57 57 / 58%)",
                                padding: "2px",
                                border: "none",
                                borderRadius: "5px",
                              }}
                            >
                              <input
                                type="checkbox"
                                checked={selectAll}
                                onChange={handleToggleAll}
                              />
                            </button>
                            Name
                          </th>
                          <th>Mobile</th>
                          <th>Amount</th>
                          <th>Item</th>
                          <th>Assigned_To</th>
                          <th>Status</th>
                          <th>Edit / Delete / Print</th>
                        </tr>
                      </thead>
                    </table>
                  </div>
                  <div className="tbl-content">
                    <table cellPadding="0" cellSpacing="0" border="0">
                      <tbody>
                        {OrderD.map((item, index) => {
                          let orderStatus = "";
                          if (item.orderStatus === 1) {
                            orderStatus = "New Order";
                          } else if (item.orderStatus === 2) {
                            orderStatus = "Call Done";
                          } else if (item.orderStatus === 3) {
                            orderStatus = "Courier Done";
                          } else if (item.orderStatus === 4) {
                            orderStatus = "Print Done";
                          } else if (item.orderStatus === 5) {
                            orderStatus = "Cancel";
                          } else if (item.orderStatus === 6) {
                            orderStatus = "Return";
                          } else if (item.orderStatus === 7) {
                            orderStatus = "Call Not-Receice";
                          } else if (item.orderStatus === 8) {
                            orderStatus = "Ask-Advance";
                          } else if (item.orderStatus === 9) {
                            orderStatus = "Ship-Later";
                          } else if (item.orderStatus === 10) {
                            orderStatus = "Waiting";
                          } else if (item.orderStatus === 11) {
                            orderStatus = "Overseas";
                          } else if (item.orderStatus === 12) {
                            orderStatus = "Delivery-Issue";
                          }

                          return (
                            <tr key={item._id}>
                              <td style={{ textAlign: "left" }}>
                                <button
                                  style={{
                                    marginRight: "5px",
                                    backgroundColor: "rgb(23 57 57 / 58%)",
                                    padding: "2px",
                                    border: "none",
                                    borderRadius: "5px",
                                  }}
                                >
                                  <input
                                    type="checkbox"
                                    checked={selectedItems.includes(item)}
                                    onChange={() => handleToggleItem(item)}
                                  />
                                </button>
                                {item.cName.slice(0, 15)}
                              </td>
                              <td
                                onClick={() => {
                                  setmodalData(item);
                                  setOpenModal(true);
                                  setRefresh(false);
                                }}
                              >
                                {item.cMobile}
                              </td>
                              <td
                                onClick={() => {
                                  setmodalData(item);
                                  setOpenModal(true);
                                  setRefresh(false);
                                }}
                              >
                                {item.totalPrice}
                              </td>
                              <td
                                onClick={() => {
                                  setmodalData(item);
                                  setOpenModal(true);
                                  setRefresh(false);
                                }}
                              >
                                {item.item}
                              </td>
                              <td
                                onClick={() => {
                                  setmodalData(item);
                                  setOpenModal(true);
                                  setRefresh(false);
                                }}
                              >
                                {item.assignedName
                                  ? item.assignedName
                                  : "Admin"}
                              </td>
                              <td
                                onClick={() => {
                                  setmodalData(item);
                                  setOpenModal(true);
                                  setRefresh(false);
                                }}
                              >
                                <button
                                  style={{
                                    display: "flex",
                                    marginLeft: "10px",
                                  }}
                                  className={
                                    item.orderStatus === 6
                                      ? "colorRed"
                                      : "td-button"
                                  }
                                >
                                  {orderStatus}
                                </button>
                              </td>
                              <td>
                                <button
                                  className="td-button"
                                  onClick={() => {
                                    navigate("/edit-order", { state: item });
                                  }}
                                >
                                  Edit
                                </button>

                                <button
                                  className="td-button delete"
                                  onClick={() => AlartSubmit(item._id)}
                                >
                                  Delete
                                </button>
                                <button
                                  className="td-button"
                                  onClick={() => {
                                    setPrintModalData(item);
                                    setOpenPrint(true);
                                    setRefresh(false);
                                  }}
                                >
                                  Print
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </>
              )}
            </tbody>
          </table>
        </div>
      </section>
      {Totalpage > 0 && (
        <div className="pagination-order">
          <Stack spacing={2}>
            <Pagination
              count={Totalpage}
              color="primary"
              onChange={PageNumber}
            />
          </Stack>
        </div>
      )}
    </div>
  );
}

export default Order;
