import { useState, useEffect } from "react";
import "./Addproduct.css";
import axios from "axios";
import myAxios from "../AxiosUrl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { stateToHTML } from "draft-js-export-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import UploadImageCdn from "../Helper/UploadImageCdn";
import NoImage from "../../img/noinage.png";
import { useNavigate } from "react-router-dom";

function Addproduct() {
  const [refresh, forceUpdate] = useState(false);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const navigate = useNavigate();
  const [productName, setProductName] = useState("");
  const [offerMessage, setOfferMessage] = useState("");
  const [HtmlDesc, setHtmlDesc] = useState("");
  const [buyPrice, setbuyPrice] = useState("");
  const [sellingPrice, setsellingPrice] = useState("");
  const [compare, setCompare] = useState("");
  const [pCode, setpCode] = useState("");
  const [colorName, setcolorName] = useState("");
  const [quantity, setquantity] = useState("");
  const [photo, setImage] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [category, setCategory] = useState("");

  const [selectCategory, setSelectCategory] = useState("");
  const [selectva, setelectva] = useState("no");
  const [categorydata, setCategoryData] = useState("");
  const [loading, setLoading] = useState(false);
  const [variationColorN, setVariationColorN] = useState("");
  const [checkvariation, setCheckvariation] = useState("no");
  const [productPage, setProductPage] = useState(1);
  const uid = localStorage.getItem("id");
  const [rows, setRows] = useState([]);
  const [rowsSize, setRowsSize] = useState([]);

  const [Trending, setTrendingNow] = useState("0");
  const [HotDeal, setHotDeal] = useState("0");
  const [categorys, setCategorys] = useState([]);
  const [showOrderNow, setshowOrderNow] = useState("Yes");
  const [RelatedProduct, setRelatedProduct] = useState("Same");
  const [images, setImages] = useState([
    {
      id: 1,
      url: "",
    },
    {
      id: 2,
      url: "",
    },
    {
      id: 3,
      url: "",
    },
    {
      id: 4,
      url: "",
    },
  ]);

  const clearState = (state) => {
    setProductName("");
    setOfferMessage("");
    setEditorState("");
    setbuyPrice("");
    setsellingPrice("");
    setCompare("");
    setpCode("");
    setcolorName("");
    setquantity("");
    setImage("");
    setImageUrl("");
    setCategory("");
    setSelectCategory("");
    setelectva("");
    setCategoryData("");
    setVariationColorN("");
    setCheckvariation("");
    setProductPage("");
    setRows([]);
    setRowsSize([]);
    setImages([]);
    setshowOrderNow("Yes");
  };

  // Start Editor
  const handleEditorChange = (editorState) => {
    setEditorState(editorState);
    const contentState = editorState.getCurrentContent();
    setHtmlDesc(stateToHTML(contentState));
  };
  // End Editor

  const uploadCallback = (file, callback) => {
    return new Promise((resolve, reject) => {
      const reader = new window.FileReader();

      reader.onloadend = async () => {
        const form_data = new FormData();
        form_data.append("photo", file);
        const res = await UploadImageCdn(form_data);
        resolve({ data: { link: res } });
      };
      reader.readAsDataURL(file);
    });
  };
  const config = {
    image: { uploadCallback: uploadCallback },
  };
  // End Editor

  const getCategory = () => {
    myAxios
      .get(`/category/${uid}`)
      .then((res) => {
        forceUpdate(true);
        setCategory(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getCategory();
    if (category) {
      setSelectCategory([
        { _id: "958yucud65467", name: "Select Category" },
        ...category,
      ]);
    }
  }, [refresh]);

  function addRow() {
    setRows([
      ...rows,
      {
        colorName: variationColorN,
        price: "",
        buy_price: "",
        quantity: "",
        ShipFrom: "",
        DeliveryTime: "",
        PriceCompare: "",
        image: null,
        gid: Math.floor(Math.random() * 9000000976),
      },
    ]);
    setVariationColorN("");
  }

  function handleInputChange(event, index) {
    const { name, value } = event.target;
    const updatedRows = [...rows];
    updatedRows[index][name] = value;
    setRows(updatedRows);
  }

  async function handleImageChange(event, index) {
    const image = new FormData();
    image.append("photo", event?.target?.files[0]);
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        token: "zesrxdyctuutydrszrestdrxy",
      },
    };
    const url = "https://cdn.ecombd.org/upload";
    axios
      .post(url, image, config)
      .then((res) => {
        toast.success("Image uploaded to CDN");
        const updatedRows = [...rows];
        updatedRows[index].image = res.data;
        setRows(updatedRows);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const addRowSize = () => {
    const newRow = {
      colorName: variationColorN,
      colorImage: "",
      sizes: [
        {
          sizeName: "S",
          price: "",
          quantity: "",
          gid: Math.floor(Math.random() * 9000000976),
        },
        {
          sizeName: "L",
          price: "",
          quantity: "",
          gid: Math.floor(Math.random() * 9000000976),
        },
        {
          sizeName: "M",
          price: "",
          quantity: "",
          gid: Math.floor(Math.random() * 9000000976),
        },
        {
          sizeName: "XL",
          price: "",
          quantity: "",
          gid: Math.floor(Math.random() * 9000000976),
        },
        {
          sizeName: "XXL",
          price: "",
          quantity: "",
          gid: Math.floor(Math.random() * 9000000976),
        },
        {
          sizeName: "3XL",
          price: "",
          quantity: "",
          gid: Math.floor(Math.random() * 9000000976),
        },
        {
          sizeName: "Set Name",
          price: "",
          quantity: "",
          gid: Math.floor(Math.random() * 9000000976),
        },
        {
          sizeName: "Set Name",
          price: "",
          quantity: "",
          gid: Math.floor(Math.random() * 9000000976),
        },
        {
          sizeName: "Set Name",
          price: "",
          quantity: "",
          gid: Math.floor(Math.random() * 9000000976),
        },
      ],
    };
    setRowsSize([...rowsSize, newRow]);
    setVariationColorN("");
  };

  const handleInputChangeSize = (event, colorIndex, sizeIndex) => {
    const { name, value } = event.target;
    const newRows = [...rowsSize];
    newRows[colorIndex].sizes[sizeIndex][name] = value;
    setRowsSize(newRows);
  };

  const handleImageChangeSize = (event, colorIndex, sizeIndex) => {
    const image = new FormData();
    image.append("photo", event?.target?.files[0]);
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        token: "zesrxdyctuutydrszrestdrxy",
      },
    };
    const url = "https://cdn.ecombd.org/upload";
    axios
      .post(url, image, config)
      .then((res) => {
        toast.success("Image uploaded to CDN");
        const newRows = [...rowsSize];
        newRows[colorIndex].colorImage = res.data;
        setRowsSize(newRows);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleRemoveSize = (colorIndex, sizeIndex) => {
    const newRows = [...rowsSize];
    newRows[colorIndex].sizes.splice(sizeIndex, 1);
    setRowsSize(newRows);
  };

  const cdnImage = () => {
    const image = new FormData();
    image.append("photo", photo);
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        token: "zesrxdyctuutydrszrestdrxy",
      },
    };
    const url = "https://cdn.ecombd.org/upload";
    axios
      .post(url, image, config)
      .then((res) => {
        setImageUrl(res.data);
        console.log("res=>", res.data);
        toast.success("Image uploaded");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const ImageUpload = async (imgFile) => {
    const image = new FormData();
    image.append("photo", imgFile);
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        token: "zesrxdyctuutydrszrestdrxy",
      },
    };
    const url = "https://cdn.ecombd.org/upload";
    const res = await axios.post(url, image, config);

    return res.data;
  };

  const handleImageSet = async (e, imageId) => {
    const file = e.target.files[0];
    const imgUrl = await ImageUpload(file);
    const newImages = images.map((image) => {
      if (image.id === imageId) {
        return { ...image, url: imgUrl };
      }
      return image;
    });
    setImages(newImages);
  };

  useEffect(() => {
    if (photo) {
      cdnImage();
    }
  }, [photo]);
  const submitH = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (selectva === "no") {
      if (
        !buyPrice.length ||
        !sellingPrice ||
        !quantity ||
        !colorName ||
        !HtmlDesc ||
        !productName ||
        !images[0].url ||
        !categorydata ||
        !pCode
      ) {
        toast.error("All Value Require");
        return setLoading(false);
      }
    }
    if (selectva === "color") {
      if (
        !rows.length ||
        !buyPrice.length ||
        !sellingPrice ||
        !HtmlDesc ||
        !productName ||
        !images[0].url ||
        !categorydata ||
        !pCode
      ) {
        toast.error("All Value Require");
        return setLoading(false);
      }
    }
    if (selectva === "colorSize") {
      if (
        !rowsSize.length ||
        !buyPrice.length ||
        !sellingPrice ||
        !HtmlDesc ||
        !productName ||
        !images[0].url ||
        !categorydata ||
        !pCode
      ) {
        toast.error("All Value Require");
        return setLoading(false);
      }
    }
    const url = `add-product`;
    const formData1 = {
      productName,
      productDesc: HtmlDesc,
      offerMessage: offerMessage,
      buyPrice,
      sellingPrice,
      colorName,
      quantity,
      imageUrl,
      images,
      showOrderNow,
      HotDeal,
      Trending,
      categorys,
      RelatedProduct,
      category: categorydata,
      uid: uid,
      variation: "no",
      pCode,
      compare,
      gid: Math.floor(Math.random() * 9000000976),
    };
    const formData2 = {
      productName,
      productDesc: HtmlDesc,
      offerMessage: offerMessage,
      buyPrice,
      sellingPrice,
      imageUrl,
      images,
      showOrderNow,
      categorys,
      HotDeal,
      Trending,
      RelatedProduct,
      category: categorydata,
      uid: uid,
      variation: "color",
      variationData: rows,
      pCode,
      compare,
      productPage,
    };
    const formData3 = {
      productName,
      productDesc: HtmlDesc,
      offerMessage: offerMessage,
      buyPrice,
      sellingPrice,
      imageUrl,
      images,
      showOrderNow,
      HotDeal,
      Trending,
      categorys,
      RelatedProduct,
      category: categorydata,
      uid: uid,
      variation: "colorSize",
      variationData: rowsSize,
      pCode,
      compare,
    };

    let formData;
    if (selectva === "no") {
      formData = formData1;
    }
    if (selectva === "color") {
      formData = formData2;
    }
    if (selectva === "colorSize") {
      formData = formData3;
    }
    myAxios
      .post(url, formData)
      .then((res) => {
        navigate("/product");
        setLoading(false);
        toast.success(res.data.message);
        clearState();
      })
      .catch((error) => {
        console.log("error");
      });
  };

  function handleRemove(index) {
    const updatedRows = [...rows];
    updatedRows.splice(index, 1);
    setRows(updatedRows);
  }
  function handleRemoveColorSize(index) {
    const updatedRows = [...rowsSize];
    updatedRows.splice(index, 1);
    setRowsSize(updatedRows);
  }

  return (
    <div>
      <ToastContainer />
      <div className="Product-Video">
        <div>
          How dose it work ➡️{" "}
          <a href="#" target="_blank">
            Video Link
          </a>{" "}
        </div>
      </div>
      <div className="add-product">
        <div className="add-sub">
          <h3>ADD PODUCT</h3>
          {/* <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} /> */}
          <input
            onChange={(e) => {
              setProductName(e.target.value);
            }}
            type="text"
            className="form__input"
            id="name"
            placeholder="Product Title"
            required=""
            value={productName}
          />
          <div style={{ padding: "10px" }}>
            <div
              style={{
                backgroundColor: "rgb(255, 226, 226)",
                border: "3px solid #fff",
              }}
            >
              <Editor
                editorState={editorState}
                placeholder="Write product description here"
                onEditorStateChange={handleEditorChange}
                toolbar={config}
              />
            </div>
          </div>
          <input
            onChange={(e) => {
              setOfferMessage(e.target.value);
            }}
            type="text"
            className="form__input"
            id="name"
            placeholder="Offer Message: Example if you buy 3 will get 300 taka discount (Optional)"
            required=""
            value={offerMessage}
          />
          <input
            onChange={(e) => {
              setbuyPrice(e.target.value);
            }}
            type="number"
            className="form__input"
            id="name"
            placeholder="Buying Price"
            required=""
            value={buyPrice}
          />
          <input
            onChange={(e) => {
              setsellingPrice(e.target.value);
            }}
            type="number"
            className="form__input"
            id="name"
            placeholder="Selling Price"
            required=""
            value={sellingPrice}
          />
          <input
            onChange={(e) => {
              setCompare(e.target.value);
            }}
            type="number"
            className="form__input"
            id="name"
            placeholder="Compare Price"
            required=""
            value={compare}
          />
          <input
            onChange={(e) => {
              setpCode(e.target.value);
            }}
            type="text"
            className="form__input"
            id="name"
            placeholder="Product Code CDR-001"
            required=""
            value={pCode}
          />
          <label>Select Variation Type</label>
          <select
            name="cars"
            className="category-addproduct"
            onChange={(e) => {
              setelectva(e.target.value);
              if (e.target.value === "color") {
                setCheckvariation("color");
              } else if (e.target.value === "colorSize") {
                setCheckvariation("colorSize");
              } else {
                setCheckvariation("no");
              }
            }}
          >
            <option value="no">No Variation</option>
            <option value="color">Color</option>
            <option value="colorSize">Color & Size</option>
          </select>
          {checkvariation === "color" && (
            <div className="variation-addproduct">
              <input
                type="text"
                className="variation-input"
                id="name"
                placeholder="ColorName"
                required=""
                value={variationColorN}
                onChange={(e) => {
                  setVariationColorN(e.target.value);
                }}
              />
              <button className="variation-button" onClick={() => addRow()}>
                Create Color
              </button>
            </div>
          )}
          <div className="display-variation">
            {rows.map((row, index) => (
              <>
                <div className="about-variation">
                  <div style={{ width: "100%", textAlign: "left" }}>Photo</div>
                  <div style={{ width: "100%" }}>Sku</div>
                  <div style={{ width: "100%" }}>Price</div>
                  <div style={{ width: "100%" }}>Compare</div>
                  <div style={{ width: "100%" }}>Buying</div>
                  <div style={{ width: "100%" }}>Quantity</div>
                  <div style={{ width: "100%" }}>ShipFrom</div>
                  <div style={{ width: "100%" }}>Time</div>
                  <div style={{ width: "100%", textAlign: "right" }}>
                    Upload
                  </div>
                  <div style={{ width: "100%", textAlign: "right" }}>
                    Delete
                  </div>
                </div>
                <div className="display-variation-item" key={index}>
                  <img
                    style={{ marginRight: "5px" }}
                    src={row.image ? row.image : NoImage}
                    height={65}
                    width={65}
                  />
                  <h2 className="va-item-p">{row.colorName}</h2>
                  <input
                    type="text"
                    className="va-inpute"
                    name="price"
                    placeholder="Price"
                    value={row.price}
                    onChange={(event) => handleInputChange(event, index)}
                    required=""
                  />
                  <input
                    type="text"
                    className="va-inpute"
                    name="PriceCompare"
                    placeholder="Compare Price"
                    value={row.PriceCompare}
                    onChange={(event) => handleInputChange(event, index)}
                    required=""
                  />
                  <input
                    type="text"
                    className="va-inpute"
                    name="buy_price"
                    placeholder="Buying Price"
                    value={row.buy_price}
                    onChange={(event) => handleInputChange(event, index)}
                    required=""
                  />
                  <input
                    type="text"
                    className="va-inpute"
                    name="quantity"
                    placeholder="Quantity"
                    value={row.quantity}
                    onChange={(event) => handleInputChange(event, index)}
                    required=""
                  />
                  <input
                    type="text"
                    className="va-inpute"
                    name="ShipFrom"
                    placeholder="ShipFrom"
                    value={row.ShipFrom}
                    onChange={(event) => handleInputChange(event, index)}
                    required=""
                  />
                  <input
                    type="text"
                    className="va-inpute"
                    name="DeliveryTime"
                    placeholder="DeliveryTime"
                    value={row.DeliveryTime}
                    onChange={(event) => handleInputChange(event, index)}
                    required=""
                  />
                  <input
                    type="file"
                    className="myFile-var"
                    name="photo"
                    onChange={(event) => handleImageChange(event, index)}
                  />
                  <button
                    className="var-item-remove"
                    onClick={() => handleRemove(index)}
                  >
                    X
                  </button>
                </div>
              </>
            ))}
          </div>

          {checkvariation === "color" && (
            <select
              name="productPage"
              className="category-addproduct"
              onChange={(e) => setProductPage(e.target.value)}
            >
              <option value="1">Page-1</option>
              <option value="2">Page-2</option>
            </select>
          )}
          {/* add color and size start */}
          <div>
            {checkvariation === "colorSize" && (
              <div className="variation-addproduct">
                <input
                  type="text"
                  className="variation-input"
                  id="name"
                  placeholder="ColorSize"
                  required=""
                  value={variationColorN}
                  onChange={(e) => {
                    setVariationColorN(e.target.value);
                  }}
                />
                <button
                  className="variation-button"
                  onClick={() => addRowSize()}
                >
                  Create Color
                </button>
              </div>
            )}
          </div>
          {/* add color and size Start */}
          {rowsSize.map((row, colorIndex) => {
            return (
              <>
                <div className="ColorSizeMain" key={colorIndex}>
                  <img
                    style={{ marginRight: "5px" }}
                    src={row.colorImage ? row.colorImage : NoImage}
                    height={100}
                    width={100}
                  />
                  <input
                    type="file"
                    className="myFile-var"
                    name="photo"
                    onChange={(event) =>
                      handleImageChangeSize(event, colorIndex)
                    }
                  />
                  <div
                    className="remove-color-size"
                    onClick={handleRemoveColorSize}
                  >
                    X
                  </div>
                  <div className="color-Size-Title">{row.colorName}</div>
                  {row.sizes.map((size, sizeIndex) => {
                    return (
                      <>
                        <div className="SizeName-input" key={sizeIndex}>
                          <div>
                            <input
                              type="text"
                              className="size-inpute"
                              name="sizeName"
                              placeholder="sizeName"
                              value={size.sizeName}
                              onChange={(event) =>
                                handleInputChangeSize(
                                  event,
                                  colorIndex,
                                  sizeIndex
                                )
                              }
                              required=""
                            />
                            <input
                              type="text"
                              className="size-inpute"
                              name="price"
                              placeholder="Price"
                              value={size.price}
                              onChange={(event) =>
                                handleInputChangeSize(
                                  event,
                                  colorIndex,
                                  sizeIndex
                                )
                              }
                              required=""
                            />
                            <input
                              type="text"
                              className="size-inpute"
                              name="quantity"
                              placeholder="Quantity"
                              value={size.quantity}
                              onChange={(event) =>
                                handleInputChangeSize(
                                  event,
                                  colorIndex,
                                  sizeIndex
                                )
                              }
                              required=""
                            />

                            <button
                              className="var-item-remove"
                              onClick={() =>
                                handleRemoveSize(colorIndex, sizeIndex)
                              }
                            >
                              X
                            </button>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
              </>
            );
          })}

          {/* add color and size end */}
          {checkvariation === "no" && (
            <input
              onChange={(e) => {
                setcolorName(e.target.value);
              }}
              type="text"
              className="form__input"
              id="name"
              placeholder="Color"
              required=""
              value={colorName}
            />
          )}
          {checkvariation === "no" && (
            <input
              onChange={(e) => {
                setquantity(e.target.value);
              }}
              type="number"
              className="form__input"
              id="name"
              placeholder="Quantity"
              required=""
              value={quantity}
            />
          )}
          <select
            name="category"
            className="category-addproduct"
            onChange={(e) => setCategoryData(e.target.value)}
          >
            {selectCategory &&
              selectCategory.map((data) => {
                return (
                  <option
                    value={data._id}
                    key={data._id}
                    defaultValue={data._id}
                  >
                    {data.name}
                  </option>
                );
              })}
          </select>
          <label>অর্ডার নাউ বাটন শো করতে চান ?</label>
          <select
            name="showOrderNow"
            className="category-addproduct"
            value={showOrderNow}
            defaultValue={showOrderNow}
            onChange={(e) => setshowOrderNow(e.target.value)}
          >
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>

          <label>রিলেটেড প্রোডাক্ট কি হবে সিলেক্ট করুন</label>
          <select
            name="RelatedProduct"
            className="category-addproduct"
            value={RelatedProduct}
            defaultValue={RelatedProduct}
            onChange={(e) => setRelatedProduct(e.target.value)}
          >
            <option value="Same">Same Category</option>
            <option value="All">All Product</option>
          </select>

          <label>ট্রেন্ডিং নাউ</label>
          <select
            name="RelatedProduct"
            className="category-addproduct"
            value={Trending}
            defaultValue={Trending}
            onChange={(e) => setTrendingNow(e.target.value)}
          >
            <option value="0">No</option>
            <option value="1">Yes</option>
          </select>

          <label>হট ডিল</label>
          <select
            name="RelatedProduct"
            className="category-addproduct"
            value={HotDeal}
            defaultValue={HotDeal}
            onChange={(e) => setHotDeal(e.target.value)}
          >
            <option value="0">No</option>
            <option value="1">Yes</option>
          </select>

          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {images.map((data) => {
              return (
                <div key={data.id} style={{ margin: "10px" }}>
                  <img
                    style={{
                      margin: "0 auto",
                      border: "2px solid",
                      padding: "7px",
                    }}
                    src={data.url || NoImage}
                    alt={`Image ${data.id}`}
                    height={150}
                    width={150}
                  />
                  <div className="upload-image">
                    <input
                      onChange={(e) => {
                        handleImageSet(e, data.id);
                      }}
                      type="file"
                      className="myFile"
                      name={`photo_${data.id}`}
                    ></input>
                  </div>
                </div>
              );
            })}
          </div>
          <button onClick={submitH} className="sub-button">
            {loading ? "Loading.." : "Add Product"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default Addproduct;
