import React, { useState, useEffect } from "react";
import "./EditTeamModal.css";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import AxiosUrl from "../AxiosUrl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

function Modal({ setOpenModal, data }) {
  const [name, setName] = useState(data.name);
  const [mobile, setMobile] = useState(data.mobile);
  const [password, setPassword] = useState();
  const [loading, setloading] = useState(false);
  const [userStatus, setStatus] = useState(data.userStatus);

  const uid = localStorage.getItem("id");
  const fromData = {
    id: data._id,
    name,
    mobile,
    password,
    userStatus
  };
  const submitCategory = () => {
    setloading(true);
    AxiosUrl.post(`/edit-team/${uid}`, fromData)
      .then((res) => {
        toast.success(res.data)
        setOpenModal(false);
        setloading(false);
        setName("");
        setMobile("")
        setPassword("")
      })
      .catch((error) => {});
  };

  return (
    <div className="modalBackground">
      <ToastContainer />
      <div className="modalContainer">
        <div className="titleCloseBtn">
          <button onClick={() => setOpenModal(false)}>X</button>
        </div>
        <div className="title">
          <Box sx={{ width: 500, maxWidth: "100%", marginBottom:"10px" }}>
            <TextField
              fullWidth
              label="Edit Name"
              id="fullWidth"
              onChange={(e) => setName(e.target.value)}
              value={name}
            />
          </Box>
          <Box sx={{ width: 500, maxWidth: "100%", marginBottom:"10px" }}>
            <TextField
              fullWidth
              label="Edt Mobile"
              id="fullWidth"
              type="number"
              onChange={(e) => setMobile(e.target.value)}
              value={mobile}
            />
          </Box>
          <Box sx={{ width: 500, maxWidth: "100%" }}>
            <TextField
              fullWidth
              label="Update Password"
              id="fullWidth"
              placeholder="Optional"
              type="password"
              onChange={(e) => setOpenModal(e.target.value)}
              value={password}
            />
          </Box>
          <select
          name="status"
          onChange={(e) => setStatus(e.target.value)}
          value={userStatus}
        >
          <option value="0">Active</option>
          <option value="1">Deactive</option>
        </select>
        </div>

        <div className="footer">
          <button
            onClick={() => {
              setOpenModal(false);
            }}
            id="cancelBtn"
          >
            Cancel
          </button>
          <button
            onClick={() => {
              submitCategory();
            }}
          >
            {loading ? "Loading.." : "Update"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default Modal;
