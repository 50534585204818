import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useNavigate } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import { ToastContainer, toast } from "react-toastify";
import ReactLoading from "react-loading";
import myAxios from "../AxiosUrl";
import Pagination from "@mui/material/Pagination"; // Import Pagination from MUI
import "react-toastify/dist/ReactToastify.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import "./Stock.css";
import SearchDesktop from "../Search/SearchDesktop";

function ProductPage() {
  const uid = localStorage.getItem("id");
  const [products, setProduct] = useState([]);
  const [refresh, setRefresh] = useState();
  const url = `get-products/${uid}`;
  const navigate = useNavigate();
  const [loading, setloading] = useState(false);
  const [searchResult, setSearchResult] = useState([]);

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 10;

  const AlartSubmit = (id) => {
    confirmAlert({
      title: "DELETE CONFIRMATION",
      message: "Are you sure to delete the product.",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteUser(id),
        },
        {
          label: "No",
          onClick: () => null,
        },
      ],
    });
  };

  const deleteUser = (id) => {
    myAxios
      .delete(`delete/${id}`)
      .then((res) => {
        setRefresh(0);
        toast.success(res.data ? "Delete success" : "");
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  useEffect(() => {
    setloading(true);
    myAxios
      .get(url)
      .then((res) => {
        setProduct(res.data);
        setloading(false);
      })
      .catch((error) => {
        console.log(error.response);
        setloading(false);
      });
  }, [refresh]);

  const data = searchResult.length > 0 ? searchResult : products;

  // Calculate the products for the current page
  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = data.slice(indexOfFirstProduct, indexOfLastProduct);

  // Handle pagination change
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  return (
    <div style={{ minHeight: "90vh" }}>
      <div className="product-Video">
        <div>
          প্রোডাক্ট পেইজ কিভাবে কাজ করে দেখুন ➡️{" "}
          <a href="#" target="_blank">
            VIDEO LINK
          </a>{" "}
        </div>
      </div>
      <ToastContainer />
      <section>
        <div className="stock-search-container">
          <Button variant="contained" onClick={() => navigate("/add-product")}>
            Add Product
          </Button>
          <SearchDesktop
            products={products}
            setProduct={setProduct}
            searchResult={searchResult}
            setSearchResult={setSearchResult}
          />
        </div>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Photo</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Buying Price</TableCell>
                <TableCell>Selling Price</TableCell>
                <TableCell>Color</TableCell>
                <TableCell>Quantity</TableCell>
                <TableCell>Edit/Delete</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading && (
                <ReactLoading
                  type="cylon"
                  color="rgb(226,115,29)"
                  height={60}
                  width={60}
                />
              )}
              {!loading && currentProducts.length <= 0 ? (
                <div className="no-data-available">No data available</div>
              ) : (
                currentProducts.map((product) => {
                  let quantity = 0;

                  if (product) {
                    if (product.variation === "color") {
                      product.variationData.forEach((data) => {
                        quantity += data.quantity * 1;
                      });
                    } else if (product.variation === "colorSize") {
                      product.variationData.forEach((data) => {
                        data.sizes.forEach((d) => {
                          quantity += d.quantity * 1;
                        });
                      });
                    } else {
                      quantity = product.quantity;
                    }
                  }

                  return (
                    <TableRow key={product._id}>
                      <TableCell>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            position: "relative",
                          }}
                        >
                          <img
                            style={{
                              width: 70,
                              height: 70,
                              borderRadius: "12px",
                            }}
                            src={
                              product.images[0] && product.images[0].url
                                ? product.images[0].url
                                : product.imgeUrl
                            }
                            alt="product"
                          />
                          {product.HotDeal === "1" && (
                            <p
                              style={{
                                backgroundColor: "red",
                                padding: "2px",
                                marginLeft: "2px",
                                borderRadius: "10px",
                                color: "#fff",
                                position: "absolute",
                                top: "40px",
                              }}
                            >
                              HOT
                            </p>
                          )}
                          {product.Trending === "1" && (
                            <p
                              style={{
                                backgroundColor: "red",
                                padding: "2px",
                                marginLeft: "2px",
                                borderRadius: "10px",
                                color: "#fff",
                                position: "absolute",
                                top: "-25px",
                              }}
                            >
                              TREND
                            </p>
                          )}
                        </div>
                      </TableCell>
                      <TableCell>{product.productName}</TableCell>
                      <TableCell>{product.priceBuy}</TableCell>
                      <TableCell>{product.proceSell}</TableCell>
                      <TableCell>
                        {product.color ? product.color : "Edit to See"}
                      </TableCell>
                      <TableCell>{quantity}</TableCell>
                      <TableCell>
                        <button
                          onClick={() => {
                            navigate("/product-edit", { state: product });
                          }}
                          className="td-button"
                        >
                          Edit
                        </button>
                        <button
                          onClick={() => AlartSubmit(product._id)}
                          className="td-button td-d-button"
                        >
                          Delete
                        </button>
                      </TableCell>
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {/* Pagination Component */}
        <Pagination
          count={Math.ceil(data.length / productsPerPage)}
          page={currentPage}
          onChange={handlePageChange}
          color="primary"
          style={{
            marginTop: "20px",
            display: "flex",
            justifyContent: "center",
          }}
        />
      </section>
    </div>
  );
}

export default ProductPage;
